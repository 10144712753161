/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef } from 'react';
import Grid from 'components/uiKit/grid';
import style from '../style.module.scss';
export function RightSection() {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    };
  }, [videoRef]);
  return <Grid horizontalAlgin="flex-end" padding={['pe-5']} responsiveWidth={{
    sm: 100,
    md: '55%'
  }} data-sentry-element="Grid" data-sentry-component="RightSection" data-sentry-source-file="rightSection.tsx">
      <div className={style['video-container']}>
        <video ref={videoRef} src="/images/banner-player/banner-video.mp4" loop muted className={`${style['video-player']} ${style.visible}`} />
      </div>
    </Grid>;
}