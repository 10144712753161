import Button from 'components/uiKit/buttons';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import React, { useContext, useEffect, useState } from 'react';
import { formatCurrencySymbol } from 'utils/functions/wallet';
import OneLinePayments from 'assets/icons/banks/oneLine';
import GlobalContext from 'context/global';
import InputGroup from 'components/uiKit/inputs/inputGroup';
import { useRouter } from 'next/router';
import { isValidDepositAmount, parseAmountValue } from 'utils/functions/ui/format';
import style from '../style.module.scss';
const values = [40, 80, 200];
const currency = 'EUR';
export function LeftSection() {
  const [value, setValue] = useState<any>({
    price: '',
    type: 'btn'
  });
  const {
    appType
  } = useContext(GlobalContext);
  const isDektop = appType === 'desktop';
  const [isPrice, setIsPrice] = useState<'insufficient' | 'valid' | 'invalid' | ''>('');
  const {
    push
  } = useRouter();
  useEffect(() => {
    if (value.price === '') {
      setIsPrice('');
    } else if (isValidDepositAmount(value.price)) {
      setIsPrice('valid');
    } else {
      setIsPrice('invalid');
    }
  }, [value.price]);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      price: parseAmountValue(event.target.value),
      type: 'input'
    });
  };
  const getStatus = () => {
    if (!value) {
      return '';
    }
    if (isPrice === 'invalid' || isPrice === 'insufficient') {
      return 'error';
    }
    return 'success';
  };
  return <Grid horizontalAlgin="center" verticalAlgin="center" gap="1rem" wrap="wrap" responsiveWidth={{
    sm: 100,
    md: '45%',
    lg: '68%'
  }} data-sentry-element="Grid" data-sentry-component="LeftSection" data-sentry-source-file="leftSection.tsx">
      <Grid horizontalAlgin="center" gap="1rem" padding={isDektop ? [] : ['pe-4', 'ps-4']} data-sentry-element="Grid" data-sentry-source-file="leftSection.tsx">
        <Grid horizontalAlgin="center" style={{
        color: '#fff'
      }} data-sentry-element="Grid" data-sentry-source-file="leftSection.tsx">
          <Typography translateGroup="title-banner-home" translateKey="title" color="#fff" weight={700} className={style.title} data-display={appType} data-sentry-element="Typography" data-sentry-source-file="leftSection.tsx" />
        </Grid>
        <Grid horizontalAlgin="center" data-display={appType} className={style['conteiner-subtitle']} padding={['pb-2']} style={{
        color: '#fff'
      }} data-sentry-element="Grid" data-sentry-source-file="leftSection.tsx">
          <Typography translateGroup="subtitle-banner-home" translateKey="subtitle" color="#fff" data-display={appType} className={style.subtitle} data-sentry-element="Typography" data-sentry-source-file="leftSection.tsx" />
        </Grid>
      </Grid>
      <Grid horizontalAlgin="center" verticalAlgin="center" wrap={isDektop ? 'nowrap' : 'wrap'} gap="0.875rem" className={style['container-form']} padding={isDektop ? [] : ['pe-4', 'ps-4']} data-display={appType} data-sentry-element="Grid" data-sentry-source-file="leftSection.tsx">
        <Grid gap="0.875rem" wrap="nowrap" horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="leftSection.tsx">
          {values.map(btnValue => <Button key={`banner-form-btn-${btnValue}`} id={`banner-form-btn-${btnValue}`} block color={value.price === parseAmountValue(btnValue).toString() ? 'white' : 'outline-white'} onClick={() => setValue({
          price: parseAmountValue(btnValue).toString(),
          type: 'btn'
        })} className={style['button-price']}>
              <Typography className={style['button-text']}>
                {formatCurrencySymbol(btnValue, currency)}
              </Typography>
            </Button>)}
        </Grid>
        <Grid responsiveWidth={{
        sm: 100,
        lg: '170px',
        xxl: '170px'
      }} data-sentry-element="Grid" data-sentry-source-file="leftSection.tsx">
          <InputGroup id="value" name="value" value={value.price} inputType="number" dataInput="true" status={getStatus()} inputProps={{
          className: style['input-price'],
          placeholder: 'MUU SUMMA',
          inputMode: 'decimal'
        }} onChange={handleInputChange} data-sentry-element="InputGroup" data-sentry-source-file="leftSection.tsx" />
        </Grid>
      </Grid>
      <Grid style={{
      position: 'relative'
    }} horizontalAlgin="center" hidden={isPrice === '' || isPrice === 'valid'} data-sentry-element="Grid" data-sentry-source-file="leftSection.tsx">
        {isPrice === 'invalid' && value ? <Typography className="text-[#FF5452]" translateGroup="limit-value-feedback" translateKey="rang-valid-1-10000" weight={600} /> : isPrice === 'insufficient' && <Typography translateGroup="limit-value-feedback" translateKey="insufficient-funds" weight={600} />}
      </Grid>
      <Grid style={{
      position: 'relative'
    }} horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="leftSection.tsx">
        <Grid className={style['container-btn-submit']} data-display={appType} padding={isDektop ? [] : ['pe-4', 'ps-4']} data-sentry-element="Grid" data-sentry-source-file="leftSection.tsx">
          <Button id="btn-save-play" color="yellow" className={style['button-submit']} onClick={() => {
          if (isPrice && isPrice !== 'valid') {
            return;
          }
          push(`/cashier/deposit?depositAmount=${value.price}`);
        }} data-sentry-element="Button" data-sentry-source-file="leftSection.tsx">
            <Typography translateGroup="banner-home" translateKey="btn-save-play" className={style['button-submit-text']} style={{
            fontWeight: 900
          }} // TODO: refactor
          data-sentry-element="Typography" data-sentry-source-file="leftSection.tsx" />
          </Button>
        </Grid>
        <Grid data-display={appType} className={style['container-slide']} data-sentry-element="Grid" data-sentry-source-file="leftSection.tsx">
          <OneLinePayments data-sentry-element="OneLinePayments" data-sentry-source-file="leftSection.tsx" />
        </Grid>
      </Grid>
    </Grid>;
}