import Grid from 'components/uiKit/grid';
import { twMerge } from 'tailwind-merge';
import { LeftSection } from './section/leftSection';
import { RightSection } from './section/rightSection';
import styles from './style.module.scss';
export default function DesktopBanner() {
  return <Grid className={twMerge('py-[3.125rem]', styles.banner)} wrap="nowrap" verticalAlgin="center" data-sentry-element="Grid" data-sentry-component="DesktopBanner" data-sentry-source-file="index.tsx">
      <LeftSection data-sentry-element="LeftSection" data-sentry-source-file="index.tsx" />
      <RightSection data-sentry-element="RightSection" data-sentry-source-file="index.tsx" />
    </Grid>;
}